/* eslint-disable camelcase */
import type { Currency } from '@backmarket/http-api'
import type {
  Product,
  Products,
} from '@backmarket/nuxt-layer-recommendation/models/product'

import type { AlgoliaPayload, AlgoliaProductHit, Variant } from './types'

export function mapSingleAlgoliaHitToProduct(
  algoliaHit: AlgoliaProductHit,
): Product {
  const {
    id,
    brand,
    category_3,
    currency,
    backbox_grade_label,
    backbox_grade_value,
    price,
    stockRaw,
    title_model,
    image1,
    link_grade_v2,
    warranty,
    reviewRating,
    sub_title_elements,
    listingID,
    model,
    merchant_id,
    referencePrice,
    variant_fields,
  } = algoliaHit

  const description = sub_title_elements?.join(' - ') || ''

  const specEntries = Object.entries(variant_fields).map(
    ([key, specification]) => {
      const cleanSpec = specification.filter((item: Variant) => item.name)

      return [key, cleanSpec]
    },
  )
  const specifications = Object.fromEntries(specEntries)

  return {
    id,
    title: title_model,
    description,
    listingId: `${listingID}`,
    model,
    brand,
    sellerId: merchant_id?.toString(),
    category: category_3,
    grade: {
      name: backbox_grade_label,
      value: backbox_grade_value,
    },
    productPageLink: link_grade_v2,
    priceNew: referencePrice,
    price: {
      amount: price.toString(),
      currency: currency as Currency,
    },
    reviewRating,
    availableStock: stockRaw,
    warrantyDuration: warranty,
    image: image1,
    specifications,
  }
}

export function mapAlgoliaResultsToProducts(
  algoliaPayload: AlgoliaPayload,
): Products {
  return algoliaPayload.results.flatMap((result) =>
    result.hits.map(mapSingleAlgoliaHitToProduct),
  )
}
